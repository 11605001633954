/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import { useState, useContext } from 'react';
import axios from 'axios';
import { useForm, useFormContext } from 'react-hook-form';
// Import contexts
import { FormDataContext } from 'globalState/FormDataContext';
// import GetMap from 'components/shared/Map/Map';

const useSubmitForm = (setFormSubmitStatus) => {
  const [formDataState] = useContext(FormDataContext); // Get the state/dispatch of form data from FormDataContext
  const { trigger } = useFormContext(); // Get useForm methods
  const [isFetching, setIsFetching] = useState(false);
  const [APIErrorMessage, setAPIErrorMessage] = useState(null);

  // Destructure values from our formDataState (get all users values)

  const {
    ReportedByType,
    ReportType,
    Email,
    PhoneNumber,
    Title,
    Firstname,
    LastName,
    Report,
    BusServiceNumber,
    BusStation,
    BusFleetNumber,
    IncidentName,
    TimeOfIncident,
    DateOfIncidentFrom,
    DateOfIncidentTo,
    TransportMode,
    Town,
    TravelDirection,
    RoadName,
    IncidentType,
    IncidentDetails,
    OffenderImages,
    areYouAbleToSupplyTheImageViaEmail,
    CCTVControlRoom,
    LockerNumber,
    Contact,
    Bodycam,
    BodycamLogNumber,
    ApproachGroup,
    AskGroupSupport,
    WhatTypeOfRefferal,
    GetConsent,
    WhatSupport,
    SafeguardingReferrals,
    ReferralType,
    ReportToPolicy,
    ReportToPolice,
    CrimeReference,
    PhotoBase64,
    PhotoName,
    postcode,
    TrainRoute,
    RailStation,
    RailStationArea,
    TramDirection,
    TramStop,
    TramStopArea,
    address,
    LocationDetails,
  } = formDataState.formData;
  // Map all destructured vals above to an object we will send to API
  let dataToSend = {};
  // let file = [];

  // replace copy with double quotes to single
  const details = IncidentDetails.replace(/"/g, "'");
  // const whatSupport = WhatSupport.replace(/"/g, "'");
  // const refType = ReferralType.replace(/"/g, "'");

  // I am a member of public
  if (ReportedByType && ReportedByType.toLowerCase() === 'member of the public') {
    dataToSend = {
      ReportedByType: `${ReportedByType}`,
      DateOfIncidentFrom: `${DateOfIncidentFrom}`,
      DateOfIncidentTo: `${DateOfIncidentTo}`,
      LocationDetails: `${LocationDetails}`,
      IncidentType: `${IncidentType}`,
      IncidentDetails: `${details}`,
      OffenderImages: `${OffenderImages}`,
      areYouAbleToSupplyTheImageViaEmail: `${areYouAbleToSupplyTheImageViaEmail}`,
      Title: `${Title}`,
      FirstName: `${Firstname}`,
      LastName: `${LastName}`,
      Email: `${Email}`,
      PhoneNumber: `${PhoneNumber}`,
      AccurancyConsent: 'False',
      CourtConsent: 'False',
    };
    if (TransportMode && TransportMode.toLowerCase() === 'bus') {
      dataToSend = {
        ...dataToSend, // Copy the existing properties
        TransportMode: `${TransportMode}`,
        BusServiceNumber: `${BusServiceNumber}`,
        BusStation: `${BusStation}`,
        BusFleetNumber: `${BusFleetNumber}`,
        Town: `${Town}`,
        RoadName: `${RoadName}`,
      };
    } else if (TransportMode && TransportMode.toLowerCase() === 'train') {
      dataToSend = {
        ...dataToSend, // Copy the existing properties
        TransportMode: `${TransportMode}`,
        TrainRoute: `${TrainRoute}`,
        RailStation: `${RailStation}`,
        RailStationArea: `${RailStationArea}`,
      };
    } else if (TransportMode && TransportMode.toLowerCase() === 'tram') {
      dataToSend = {
        ...dataToSend, // Copy the existing properties
        TransportMode: `${TransportMode}`,
        TramDirection: `${TramDirection}`,
        TramStop: `${TramStop}`,
        TramStopArea: `${TramStopArea}`,
      };
    }
  } else {
    dataToSend = {
      ReportedByType: `${ReportedByType}`,
      DateOfIncidentFrom: `${DateOfIncidentFrom}`,
      DateOfIncidentTo: `${DateOfIncidentTo}`,
      LocationDetails: `${LocationDetails}`,
      IncidentType: `${IncidentType}`,
      IncidentDetails: `${details}`,
      OffenderImages: `${OffenderImages}`,
      areYouAbleToSupplyTheImageViaEmail: `${areYouAbleToSupplyTheImageViaEmail}`,
      CCTVControlRoom: `${CCTVControlRoom}`,
      LockerNumber: `${LockerNumber}`,
      Bodycam: `${Bodycam}`,
      BodycamLogNumber: `${BodycamLogNumber}`,
      ApproachGroup: `${ApproachGroup}`,
      AskGroupSupport: `${AskGroupSupport}`,
      WhatSupport: `${WhatSupport}`,
      SafeguardingReferrals: `${SafeguardingReferrals}`,
      ReferralType: `${ReferralType}`,
      GetConsent: `${GetConsent}`,
      ReportToPolice: `${ReportToPolice}`,
      CrimeReference: `${CrimeReference}`,
      Title: `${Title}`,
      FirstName: `${Firstname}`,
      LastName: `${LastName}`,
      Email: `${Email}`,
      PhoneNumber: `${PhoneNumber}`,
      AccurancyConsent: 'False',
      CourtConsent: 'False',
    };
    if (TransportMode && TransportMode.toLowerCase() === 'bus') {
      dataToSend = {
        ...dataToSend, // Copy the existing properties
        TransportMode: `${TransportMode}`,
        BusServiceNumber: `${BusServiceNumber}`,
        BusStation: `${BusStation}`,
        BusFleetNumber: `${BusFleetNumber}`,
        Town: `${Town}`,
        RoadName: `${RoadName}`,
      };
    } else if (TransportMode && TransportMode.toLowerCase() === 'train') {
      dataToSend = {
        ...dataToSend, // Copy the existing properties
        TransportMode: `${TransportMode}`,
        TrainRoute: `${TrainRoute}`,
        RailStation: `${RailStation}`,
        RailStationArea: `${RailStationArea}`,
      };
    } else if (TransportMode && TransportMode.toLowerCase() === 'tram') {
      dataToSend = {
        ...dataToSend, // Copy the existing properties
        TransportMode: `${TransportMode}`,
        TramDirection: `${TramDirection}`,
        TramStop: `${TramStop}`,
        TramStopArea: `${TramStopArea}`,
      };
    }
  }

  const magicTransform = (obj) => {
    function magic(e) {
      // eslint-disable-next-line prettier/prettier
      let lines = '';
      const keyO = Object.keys(e);
      const last = keyO[keyO.length - 1];
      for (const prop in e) {
        const key = prop;
        const val = e[prop];
        const line =
          key === last
            ? `&quot;${key}&quot;: &quot;${val}&quot;  <br>`
            : // eslint-disable-next-line no-useless-concat
              `&quot;${key}&quot;: &quot;${val}&quot;,  <br>` + '\n';
        lines += line;
      }
      return lines;
    }
    const caseNote = `<!DOCTYPE html><html>
  <head>
     <meta http-equiv="Content-Type" content="text/html; charset=us-ascii"><title></title>    
     <meta name="viewport" content="width=device-width, initial-scale=1">
     <meta http-equiv="X-UA-Compatible" content="IE=edge">
  </head>
  <body>
  <pre>
  {
    ${magic(obj)}
  }
  </pre>
  </body>
  </html>`;

    return btoa(caseNote);
  };

  const report = magicTransform(dataToSend);

  // console.log(dataToSend);

  // const emailKey = process.env.REACT_APP_EMAIL_KEY;
  // let emailEnum;

  // if (emailKey && emailKey.trim() !== '') {
  //   emailEnum = parseInt(emailKey, 10);
  //   // emailEnum now contains the parsed integer
  // } else {
  //   // if the value of email is a valid integer then assign 5
  //   emailEnum = 5;
  // }

  const emailEnum = 16; // 7

  const incidentTime = `${TimeOfIncident}`;

  // if (OffenderImages && OffenderImages.trim() !== '') {
  //   dataToSend.OffenderImages = OffenderImages;
  //   dataToSend['OffenderImages Name'] = PhotoName;
  // }

  // if (PhotoName && PhotoBase64 && PhotoName.trim() !== '' && PhotoBase64.trim() !== '') {
  //   file = [
  //     {
  //       name: PhotoName,
  //       content: PhotoBase64,
  //     },
  //   ];
  // } else {
  //   file = [];
  // }

  /* eslint-disable no-console */
  axios.interceptors.request.use((request) => {
    request.headers['Cross-Origin'] = 'your-cross-origin-value';
    // console.log('Starting Request', JSON.stringify(request, null, 2));
    return request;
  });
  /* eslint-enable no-console */
  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission method
    // Validation
    const result = await trigger();

    // if no errors
    if (result) {
      // Start submitting API
      setIsFetching(true); // Set this so we can put loading state on button
      // Go hit the API with the data
      axios({
        method: 'post',
        url: `${process.env.REACT_APP_API_HOST}`,
        data: JSON.stringify(dataToSend),
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'same-origin',
      })
        .then((response) => {
          // If the response is successful(200: OK) or error with validation message(400)
          if (response.status === 200 || response.status === 400) {
            const payload = response.config.data;

            // Log event to analytics/tag manager
            // window.dataLayer.push({
            //   event: 'formAbandonment',
            //   eventCategory: 'safer-travel-report-an-incident',
            //   eventAction: 'form submitted: success',
            //   eventLabel: 'success',
            // });
            setIsFetching(false); // set to false as we are done fetching now
            if (payload.Message) {
              setAPIErrorMessage(payload.Message);
            } else {
              setFormSubmitStatus(true); // Set form status to success
              window.scrollTo(0, 0); // Scroll to top of page
              // set success page
            }
            return true;
          }
          throw new Error(response.statusText, response.Message); // Else throw error and go to our catch below
        })

        // If formsubmission errors
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error({ error });
          let errMsg;

          if (error.text) {
            error.text().then((errorMessage) => {
              errMsg = errorMessage;
            });
          } else {
            errMsg = error;
          }

          // Log event to analytics/tag manager
          // window.dataLayer.push({
          //   event: 'formAbandonment',
          //   eventCategory: 'safer-travel-report-an-incident',
          //   eventAction: 'form submitted: error',
          //   eventLabel: errMsg,
          // });
          setIsFetching(false); // set to false as we are done fetching now
          setFormSubmitStatus(false); // Set form status to error
          window.scrollTo(0, 0); // Scroll to top of page
          // set error message
        });
    }
  };

  // Return handleSubmit and isFetching so it can be used by form
  return {
    handleSubmit,
    isFetching,
    APIErrorMessage,
  };
};

export default useSubmitForm;
