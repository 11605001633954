import React, { useRef, useContext } from 'react';
// Import custom hooks

// Import components
import Dropdown from 'components/shared/FormElements/Dropdown/Dropdown';
import { useFormContext } from 'react-hook-form';
import { FormDataContext } from 'globalState/FormDataContext';

const IncidentTyleList = () => {
  const { register } = useFormContext(); // Get useForm methods
  const [formDataState] = useContext(FormDataContext); // Get the state/dispatch of form data from FormDataContext

  const incidentType = formDataState.formData.IncidentType;

  const fieldValidation = register({
    required: 'Enter a Date of the incident',
  });

  const options = [
    { label: 'Choose from list', value: '' },
    { label: 'Alcohol Related/Drunken Behaviour', value: 'Alcohol Related/Drunken Behaviour' },
    { label: 'Begging/Rough Sleeping/Vagrancy', value: 'Begging/Rough Sleeping/Vagrancy' },
    { label: 'Bus Shelters', value: 'Bus Shelters' },
    {
      label: 'Criminal Damage (no permanent damage)',
      value: 'Criminal Damage (no permanent damage)',
    },
    { label: 'Cycling/Scooters', value: 'Cycling/Scooters' },
    { label: 'Disruptive Passengers', value: 'Disruptive Passengers' },
    { label: 'Drugs', value: 'Drugs' },
    { label: 'Littering', value: 'Littering' },
    {
      label: 'Missile Throwing (no damage)',
      value: 'Missile Throwing (no damage)',
    },
    { label: 'Missing Person(s)', value: 'Missing Person(s)' },
    { label: 'Noise Nuisance', value: 'Noise Nuisance' },
    { label: 'Overcrowding', value: 'Overcrowding' },
    {
      label: 'School based anti-social behaviour',
      value: 'School based anti-social behaviour',
    },
    { label: 'Sexual Offences', value: 'Sexual Offences' },
    { label: 'Smoking/Vaping', value: 'Smoking/Vaping' },
    {
      label: 'Threats towards Passengers',
      value: 'Threats towards Passengers',
    },
    {
      label: 'Threats towards Staff',
      value: 'Threats towards Staff',
    },
    { label: 'Transport related issues not ASB', value: 'Transport related issues not ASB' },
    { label: 'Trespass (Metro/Train)', value: 'Trespass (Metro/Train)' },
    {
      label: 'Unsatisfactory staff action - Bus/Train/Metro Operator',
      value: 'Unsatisfactory staff action - Bus/Train/Metro Operator',
    },
    {
      label: 'Unsatisfactory staff action - Police',
      value: 'Unsatisfactory staff action - Police',
    },
    { label: 'Unsatisfactory staff action - TFWM', value: 'Unsatisfactory staff action - TFWM' },

    { label: 'Walkers/Fare Evasion', value: 'Walkers/Fare Evasion' },
  ];

  return (
    <fieldset className="ds-fe-fieldset ds-col-1">
      <legend className="ds-fe-fieldset__legend">
        <h2 className="ds-fe-question">What type of incident happened?</h2>
      </legend>

      <Dropdown
        name="IncidentType"
        options={options}
        label="Incident type*"
        autocomplete="incident-type"
        defaultValue={incidentType}
        fieldValidation={register({
          required: `Please select one option to proceed`,
        })}
      />
    </fieldset>
  );
};

export default IncidentTyleList;
