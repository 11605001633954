import React from 'react';
import PropTypes from 'prop-types';

// Import components

const Intro = ({ setIsFormStarted }) => {
  const handleClick = () => {
    setIsFormStarted(true);
  };

  return (
    <div className="ds-col-1 ds-col-md-2-3">
      <h1>Report an Incident</h1>
      <h3>Use this service to:</h3>
      <ul>
        <li>Report antisocial behaviour</li>
      </ul>
      This process takes around 15 minutes.
      <p>
        If you need to report a crime that has already happened ,call 101 or use{' '}
        <a
          href="https://west-midlands.police.uk/contact-us/live-chat"
          target="_blank"
          rel="noreferrer"
        >
          Live Chat
        </a>{' '}
        on the West Midlands Police website.
      </p>
      <p>
        You can report a crime on the{' '}
        <a href="https://www.west-midlands.police.uk/" target="_blank" rel="noreferrer">
          West Midlands Police
        </a>{' '}
        website
      </p>
      <button
        type="button"
        className="ds-btn ds-btn--start ds-m-b-md ds-col-1 ds-col-sm-auto"
        onClick={handleClick}
      >
        Start
        <svg className="ds-btn__icon ds-btn__icon--right">
          <use xlinkHref="#ds-general-chevron-right" href="#ds-general-chevron-right" />
        </svg>
      </button>
    </div>
  );
};
Intro.propTypes = {
  setIsFormStarted: PropTypes.func.isRequired,
};
export default Intro;
